<template>
  <v-container class="dictionaries">
    <v-row>
      <v-col cols="6">
        <h1>Coupon Kampagnen</h1>
      </v-col>
    </v-row>
    <v-sheet>
      <v-data-table
        :headers="headers"
        :items="campaigns"
        :hide-default-footer="true"
        :disable-pagination="true"
        sort-by="sort_name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Neue Kampagne anlegen
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Kampagne</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row v-if="error !== ''">
                      <v-alert type="error">Fehler: {{ error }}</v-alert>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.id"
                          label="ID"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Kampagenenname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.campaignPartner"
                          label="Partner"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.voucherCount"
                          label="Anzahl Gutscheine"
                          :disabled="editedItem.codes.length > 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.campaignStart"
                              label="Start der Kampagne"
                              prepend-icon="event"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="computedStartDateFormatted"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.campaignEnd"
                              label="Ende der Kampagne"
                              prepend-icon="mdi-calendar"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="computedEndDateFormatted"
                            no-title
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.voucherCodeLength"
                          label="Länge der Gutscheincodes"
                          :disabled="editedItem.codes.length > 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.voucherCodePrefix"
                          label="Code Prefix"
                          :disabled="editedItem.codes.length > 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.message"
                          label="Nachricht bei Einlösung"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.value"
                          label="Gutschein Wert"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.type"
                          item-text="name"
                          item-value="val"
                          :items="voucherTypes"
                          label="Gutscheintyp"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.aboType"
                          item-text="name"
                          item-value="val"
                          :items="aboTypes"
                          label="Abo Typ"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.deviceCount"
                          label="Anzahl Geräte (Single 1, Family 5)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Kampagne "{{ editedItem.name }}" wirklich
                  löschen?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.campaignStart`]="{ item }">
          {{ getFormatedDate(item.campaignStart) }}
        </template>
        <template v-slot:[`item.campaignEnd`]="{ item }">
          {{ getFormatedDate(item.campaignEnd) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="!item.created">
            <router-link
              :to="{ name: 'campaign-detail', params: { id: item.id } }"
            >
              <v-icon small class="mr-2"> mdi-information </v-icon>
            </router-link>
            <v-icon small class="mr-2" @click="downloadCodes(item)">
              mdi-download
            </v-icon>
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>


<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

//    let name = campaignData.name;
//    let voucherCount = campaignData.voucherCount;
//    let campaignEnd = campaignData.campaignEnd;
//    let voucherCodeLength = campaignData.voucherCodeLength;

import { mapGetters } from "vuex";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { Timestamp } from "@/plugins/firebase";

export default {
  name: "campaigns",
  components: {},
  data: function () {
    return {
      menu1: false,
      menu2: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Partner",
          align: "start",
          sortable: false,
          value: "campaignPartner",
        },
        {
          text: "Anzahl Codes",
          align: "start",
          sortable: false,
          value: "voucherCount",
        },
        {
          text: "Start der Kampagne",
          align: "start",
          sortable: false,
          value: "campaignStart",
        },
        {
          text: "Ende der Kampagne",
          align: "start",
          sortable: false,
          value: "campaignEnd",
        },

        // { text: "In App Anzeigen", value: "show_in_app", sortable: true },

        { text: "", value: "actions", sortable: false },
      ],

      /// voucher['message'] = campaign.message;
      //   voucher['type'] = campaign.type;
      //   voucher['value'] = campaign.value;
      //   voucher['deviceCount'] = campaign.deviceCount;
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        campaignPartner: "",
        locked: 0,
        voucherCount: 0,
        campaignEnd: "",
        voucherCodeLength: 6,
        voucherCodePrefix: "",
        message: "",
        type: "free_months",
        value: 12,
        deviceCount: 1,
        codes: [],
      },
      defaultItem: {
        id: "",
        name: "",
        campaignPartner: "",
        locked: 0,
        voucherCount: 10,
        campaignEnd: null,
        voucherCodeLength: 6,
        voucherCodePrefix: "",
        message: "",
        type: "free_months",
        value: 12,
        deviceCount: 1,
        codes: [],
      },
      voucherTypes: [{ name: "Freimonate", val: "free_months" }],
      aboTypes: [
        { name: "Einzel", val: "single", devices: 1 },
        { name: "Family", val: "family", devices: 5 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      campaigns: "campaigns/getCampaigns",
      error: "campaigns/getError",
    }),
    computedStartDateFormatted: {
      get() {
        // check if this.editedItem.campaignStart has function toISOString

        if (
          this.editedItem.campaignStart &&
          this.editedItem.campaignStart != "" &&
          typeof this.editedItem.campaignStart.toISOString === "function"
        ) {
          const formattedStartDate = this.editedItem.campaignStart
            .toISOString()
            .substr(0, 10);

          return formattedStartDate;
        }
        return "";
      },
      set(date) {
        let res = new Date(date);
        this.editedItem.campaignStart = res;
      },
    },
    computedEndDateFormatted: {
      get() {
        if (
          this.editedItem.campaignEnd &&
          this.editedItem.campaignEnd != "" &&
          typeof this.editedItem.campaignEnd.toISOString === "function"
        ) {
          const formattedEndDate = this.editedItem.campaignEnd
            .toISOString()
            .substr(0, 10);
          return formattedEndDate;
        }
        return "";
      },
      set(date) {
        let res = new Date(date);
        this.editedItem.campaignEnd = res;
      },
    },
  },
  watch: {
    // watch abo type and set device count accordingly
    "editedItem.aboType"(newValue) {
      this.editedItem.deviceCount = this.aboTypes.find(
        (x) => x.val === newValue
      ).devices;
    },
  },
  created: function () {
    this.$store.dispatch("campaigns/loadCampaigns");
  },
  methods: {
    reformatStartDate(date) {
      console.log(date);
      let res = new Date(date);
      this.editedItem.campaignStart = res;
    },
    downloadCodes(campaign) {
      // create temp csv file and download it
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Code;Kampagne\n";
      campaign.codes.forEach((code) => {
        csvContent += code + ";" + campaign.name + "\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", campaign.name + ".csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    getFormatedDate(date) {
      let formattedDate = moment(date).format("DD.MM.YYYY");
      return formattedDate;
    },

    getDateFromFSTimestamp(timestamp) {
      return moment(timestamp.toDate()).format("DD.MM.YYYY");
      //   console.log("getDateFromFSTimestamp", timestamp);
      //   if (timestamp instanceof Timestamp) {
      //   } else {
      //     return moment(timestamp).format("DD.MM.YYYY");
      //   }

      //   return moment(timestamp.toDate()).format("DD.MM.YYYY");
      // else
      //   return moment(timestamp).format("DD.MM.YYYY");
    },

    editItem(item) {
      this.editedIndex = this.campaigns.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.formattedStartDate = this.editedItem.campaignStart
        ? this.editedItem.campaignStart.toISOString().substr(0, 10)
        : new Date().toISOString().substr(0, 10);

      console.log("editItem", item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.campaigns.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("campaigns/delete", this.editedItem);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      //   this.close();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedItem.id === "") {
        // create
        await this.$store.dispatch("campaigns/create", this.editedItem);
      } else {
        // save
        this.$store.dispatch("campaigns/update", this.editedItem);
      }
      if (this.error === "") {
        this.close();
      }
    },
  },
};
</script>
