<template>
  <v-container class="dictionaries">
    <v-row>
      <v-col cols="6">
        <h1>Wörterbücher</h1>
      </v-col>
    </v-row>
    <v-sheet>
      <v-data-table
        :headers="headers"
        :items="dictionaries"
        :hide-default-footer="true"
        :disable-pagination="true"
        sort-by="sort_name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Neues Wörterbuch
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Wörterbuch</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row v-if="error !== ''">
                      <v-alert type="error">Fehler: {{ error }}</v-alert>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.id"
                          label="ID"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Wörterbuch"
                        ></v-text-field>
                        <v-col cols="12" sm="6" md="6">
                          <v-checkbox
                            v-model="editedItem.locked"
                            label="gesperrt"
                          ></v-checkbox>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Wörterbuch "{{ editedItem.name }}" wirklich
                  löschen?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="!item.created">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="!item.locked"
              small
              @click="deleteItem(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
            <v-icon v-else small color="red"> mdi-lock </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { mapGetters } from "vuex";

export default {
  name: "directories",
  components: {},
  data: function () {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },

        // { text: "In App Anzeigen", value: "show_in_app", sortable: true },

        { text: "", value: "actions", sortable: false },
      ],

      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        locked: 0,
      },
      defaultItem: {
        id: "",
        name: "",
        locked: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      dictionaries: "dictionaries/getList",
      error: "dictionaries/getError",
    }),
  },
  watch: {},
  created: function () {
    // this.$store.dispatch("dictionaries/fetchConfigList");
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.dictionaries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.dictionaries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("dictionaries/delete", {
        dictionary: this.editedItem,
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      //   this.close();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedItem.id === "") {
        // create
        this.$store.dispatch("dictionaries/create", {
          dictionary: this.editedItem,
        });
      } else {
        // save
        this.$store.dispatch("dictionaries/update", {
          dictionary: this.editedItem,
        });
      }
      if (this.error === "") {
        this.close();
      }
    },
  },
};
</script>
